<!-- eslint-disable max-len -->
<template>
    <div class="invention-confirm">
        <v-form>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.name"
                            outlined
                            :label="$t('InventionApply.Confirm.label[0]')"
                            placeholder=""
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                        <v-text-field
                            v-model="form.team.school"
                            outlined
                            :label="$t('InventionApply.Confirm.label[1]')"
                            placeholder=""
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[1]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.Confirm.tips[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="teacher-list">
                        <div
                            v-for="(item, index) in form.team.teachers"
                            :key="index"
                            class="teacher-item"
                        >
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.name"
                                    outlined
                                    :label="`${$t('InventionApply.Confirm.label[2]')}${index + 1}`"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <v-text-field
                                    v-model="item.phone"
                                    outlined
                                    :label="$t('InventionApply.Confirm.label[3]')"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.title"
                                    outlined
                                    :label="$t('InventionApply.Confirm.label[4]')"
                                    placeholder=""
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <v-text-field
                                    v-model="item.email"
                                    outlined
                                    :label="$t('InventionApply.Confirm.label[5]')"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[2]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.Confirm.tips[1]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="teacher-list">
                        <div
                            v-for="(item, index) in form.team.members"
                            :key="index"
                            class="member-item"
                        >
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.name"
                                    outlined
                                    :label="memberNameLabel(index)"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <v-text-field
                                    v-model="item.phone"
                                    outlined
                                    :label="$t('InventionApply.Confirm.label[8]')"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.department"
                                    outlined
                                    :label="$t('InventionApply.Confirm.label[9]')"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <v-text-field
                                    v-model="item.email"
                                    outlined
                                    :label="$t('InventionApply.Confirm.label[10]')"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.grade"
                                    outlined
                                    :label="$t('InventionApply.Confirm.label[11]')"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <div class="member-file pdf-file">
                                    <v-icon class="icon">
                                        mdi-paperclip
                                    </v-icon>
                                    <a
                                        :href="$static(item.pdfFile)"
                                        target="_blank"
                                        class="preview-link"
                                    >{{ $t('InventionApply.Confirm.link[0]') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block team-image-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[3]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-card
                        elevation="4"
                        class="preview-card rounded-lg"
                    >
                        <v-img
                            height="300px"
                            :src="$static(form.team.previewImg)"
                            class="team-image"
                        />
                    </v-card>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.TeamData.title[4]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][0]')"
                        value="學校活動公告(學校官網、海報、育成中心等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][1]')"
                        value="獎金獵人平台"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][2]')"
                        value="FUN大視野官網"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][3]')"
                        value="FUN大視野FACEBOOK"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][4]')"
                        value="FUN大視野INSTAGRAM"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('InventionApply.TeamData.checkbox[0][5]')"
                            value="歷屆團隊推薦，團隊名:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refers1"
                            class="checkbox-input border-orange-input form-input"
                            readonly
                        />
                    </div>
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('InventionApply.TeamData.checkbox[0][6]')"
                            value="親朋好友推薦，推薦人:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refers2"
                            class="checkbox-input border-orange-input form-input"
                            readonly
                        />
                    </div>
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][7]')"
                        value="曾經提案過"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][8]')"
                        value="原本就知道"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][9]')"
                        value="慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('InventionApply.TeamData.checkbox[0][10]')"
                            value="其他:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refers3"
                            class="checkbox-input border-orange-input form-input"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <!-- 提案 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[4]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.proposal.name"
                            outlined
                            :label="$t('InventionApply.Confirm.label[12]')"
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[5]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.Confirm.tips[2]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.level"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="大專校院組"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('InventionApply.Confirm.radio[0]') }}<span
                                        class="radio-tips"
                                    > {{ $t('InventionApply.Confirm.tips[3]') }}</span>
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="高中職組"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('InventionApply.Confirm.radio[1]') }}<span class="radio-tips"> {{ $t('InventionApply.Confirm.tips[4]') }}</span>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <!-- 自選主題 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[6]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.Confirm.tips[2]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.main"
                        class="radio-group"
                        readonly
                    >
                        <div class="radio-type">
                            <div class="label">
                                {{ $t('InventionApply.Confirm.label[13]') }}
                            </div>
                            <div class="block">
                                <v-radio
                                    value="慈善 1.以賑災為主，研發備災，賑災所需相關產品。"
                                    color="#898989"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            {{ $t('InventionApply.Confirm.radio[2]') }}
                                        </div>
                                    </template>
                                </v-radio>
                                <v-radio
                                    value="慈善 2.以人文關懷為主，研發可以改善弱勢或偏鄉地區之民生或日常問題相關產品。"
                                    color="#898989"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            {{ $t('InventionApply.Confirm.radio[3]') }}
                                        </div>
                                    </template>
                                </v-radio>
                            </div>
                        </div>
                        <div class="type">
                            <div class="label">
                                {{ $t('InventionApply.Confirm.label[14]') }}
                            </div>
                            <div class="block">
                                <v-radio
                                    value="醫療 1.以長照2.0為主，研發可幫助解決銀髮族或失能之身心障礙者的生理健康或日常照護之產品。"
                                    color="#898989"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            {{ $t('InventionApply.Confirm.radio[4]') }}
                                        </div>
                                    </template>
                                </v-radio>
                                <v-radio
                                    value="醫療 2.研發可以改善醫療職業環境，提升病人安全及居家照者之產品。"
                                    color="#898989"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            {{ $t('InventionApply.Confirm.radio[5]') }}
                                        </div>
                                    </template>
                                </v-radio>
                            </div>
                        </div>
                    </v-radio-group>
                </div>
            </div>
            <!-- 自選主題 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[7]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.Confirm.tips[5]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[15]')"
                        value="SDGs1"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[16]')"
                        value="SDGs2"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[17]')"
                        value="SDGs3"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[18]')"
                        value="SDGs4"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[19]')"
                        value="SDGs5"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[20]')"
                        value="SDGs6"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[21]')"
                        value="SDGs7"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[22]')"
                        value="SDGs8"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[23]')"
                        value="SDGs9"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[24]')"
                        value="SDGs10"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[25]')"
                        value="SDGs11"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[26]')"
                        value="SDGs12"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[27]')"
                        value="SDGs13"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[28]')"
                        value="SDGs14"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[29]')"
                        value="SDGs15"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[30]')"
                        value="SDGs16"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Confirm.label[31]')"
                        value="SDGs17"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                </div>
            </div>
            <!-- 計劃書摘要 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[8]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-textarea
                        v-model="form.proposal.abstract"
                        rows="10"
                        counter="300"
                        :label="$t('InventionApply.Confirm.label[32]')"
                        no-resize
                        outlined
                        readonly
                        background-color="#F2F2F2"
                    />
                </div>
            </div>
            <!-- 計畫書 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Confirm.title[9]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="pdf-file">
                        <v-icon class="icon">
                            mdi-paperclip
                        </v-icon>
                        <a
                            :href="$static(form.proposal.pdfFile)"
                            target="_blank"
                            class="preview-link"
                        >{{ $t('InventionApply.Confirm.link[1]') }}</a>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="apply-form-block">
            <div class="left-block" />
            <div class="form-content">
                <div class="actions">
                    <div
                        class="btn white-btn"
                        @click="prevStep"
                    >
                        {{ $t('InventionApply.Confirm.button[0]') }}
                    </div>
                    <div
                        class="btn orange-btn"
                        @click="submit"
                    >
                        {{ $t('InventionApply.Confirm.button[1]') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import API from '@/api';

export default {
    components: {},
    data() {
        return {
            refers1: '',
            refers2: '',
            refers3: '',
        };
    },
    computed: {
        ...mapState('mInventionApply', ['form', 'refersTeamName', 'refersRecommendName', 'refersOther']),
        applyId() {
            return this.$route.params.id;
        },
    },
    created() {
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        ...mapActions('mInventionApply', ['loadApply']),
        async init() {
            if (this.applyId !== 'new') {
                await this.loadApply(this.applyId);
            }
            this.refers1 = this.refersTeamName;
            this.refers2 = this.refersRecommendName;
            this.refers3 = this.refersOther;
        },
        memberNameLabel(index) {
            if (index === 0) {
                return this.$t('InventionApply.Confirm.label[6]');
            }
            return `${this.$t('InventionApply.Confirm.label[7]')}${index}`;
        },
        prevStep() {
            this.$router.push({
                name: 'InventionTerms',
                params: {
                    id: this.applyId,
                },
            });
        },
        submit() {
            this.setLoading(true);
            API.InventionApplyUtil.Submit(this.applyId)
                .then(() => {
                    this.setLoading(false);
                    this.$router.push({
                        name: 'InventionComplete',
                        id: this.applyId,
                    });
                })
                .catch((e) => {
                    this.setLoading(false);
                    this.handleError({
                        message: e || this.$t('alertMessage.register-fail'),
                        color: 'error',
                    });
                });
        },
    },
};
</script>

<style lang="scss">
.invention-confirm {
  .member-file {
    flex: 1;
    display: flex;
    align-items: center;
    margin-top: -50px;
  }

  .team-image-block {
    .preview-card {
      width: 300px;
      height: 300px;
    }
  }

  .pdf-file{
    a {
      color: $color-orange;
      font-weight: 700;
      letter-spacing: 0.1em;
      margin-left: 5px;
    }
  }

  .actions {
    justify-content: center;

    .btn:first-child {
      margin-right: 30px;
    }
  }
}
.checkbox-input{
    padding-top: 0 !important;
    margin-top: 0 !important;
    width: 100%;
    flex: unset;
}
@media (min-width: 768px) {
    .checkbox-input{
        max-width: 185px !important;
    }
}
</style>
